'use client'
import React from "react"
import { hasCookie, setCookie } from "cookies-next"

import EnableGoogleAdSense from "lib/files/adsense";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = React.useState(true)

  React.useEffect(() => {
    setShowConsent(hasCookie("localConsent"))
  }, [])

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie("localConsent", "true", {})
    EnableGoogleAdSense()
  }

  if (showConsent) {
    return null;
  }

  return (
    <section className="z-30">
      <div className="fixed bottom-0 flex items-center w-full justify-between p-3 bg-primary-700">
        <div className="text-sm text-white leading-6 mr-4">
        Se utilizan cookies propias y de terceros ofrecer un mejor servicio. Al continuar navegando, se considera que acepta su uso.
        </div>
        <button className="py-2 text-sm px-8 rounded border-2 border-white text-primary-800 bg-white cursor-pointer transition-all ease-in relative inline-block hover:bg-neutral-800 hover:text-white shadow ads" onClick={() => acceptCookie()}>
          Accept
        </button>
      </div>
    </section>
  );
};

export default CookieConsent